import { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import cn from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { BsPlus, BsThreeDotsVertical, BsTrash2 } from 'react-icons/bs';
import { parsePhoneNumber } from 'libphonenumber-js';
import Popup from 'reactjs-popup';

import Table from '../../../components/Table/Table';
import SwitchView from '../../../components/SwitchView/SwitchView';
import Button, { BUTTON_TYPE } from '../../../components/Button/Button';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { useLoadingBars } from '../../../components/LoadingBar/LoadingBar';
import ListView, { SortOrder } from '../../../components/ListView/ListView';
import Modal, { useModal } from '../../../components/Modal/Modal';
import InputForm from '../../../components/InputForm/InputForm';
import IOSSwitch from '../../../components/IOSSwitch/IOSSwitch';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import {
  fieldDict,
  filterOption,
  genderTypes,
  sortFieldList,
  sortStringList,
  switchItems,
  userRoleTypes,
} from './Users.helper';

import { getLoginedUserInfo } from '../../../services/reducers/userReducer';
import { useAppSelector, useDeviceType } from '../../../services/hooks';
import {
  Gender,
  UserInfo,
  UserRole,
  addUser,
  deleteUser,
  getUsers,
  inviteUser,
  printUserRole,
  updateUserInfo,
} from '../../../apis/users';

import { ParticipantInfo } from '../../../apis/participants';
import { defaultAvatars } from '../../../utils/constants';
import {
  colorDict,
  getServerImageUrl,
  getStringByLanguage,
} from '../../../utils/utils';

import styles from './styles.module.scss';

const Documents: FC = () => {
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const { isMobile } = useDeviceType();
  const { community_id, participant_id } = useParams();
  const userInfo = useAppSelector(getLoginedUserInfo);

  const [activeView, setActiveView] = useState(1);
  const [tableUrlParam, setTableUrlParam] = useState<{
    search: string;
  }>({
    search: '',
  });

  const [listUrlParam, setListUrlParam] = useState<{
    search: string;
    sort: SortOrder;
    sortField: keyof UserInfo;
  }>({
    search: '',
    sort: SortOrder.ASC,
    sortField: 'email',
  });

  const [selectedItems, setSelectedItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<UserInfo>[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });

  const [modalItem, setModalItem] = useState<UserInfo | null>(null);
  const [modalDeleteItems, setModalDeleteItems] = useState<Partial<UserInfo>[]>(
    [],
  );
  const [modalTitle, setModalTitle] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [isEventFromAll, setIsEventFromAll] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setActiveView(0);
    }
  }, [isMobile, activeView]);

  const onViewChange = (index: number) => {
    setActiveView(index);
    setSelectedItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectAll(false);
  };

  const onRowClicked = (user: UserInfo) => {
    setModalItem(user);
    setOnlyView(true);
    cuvModal.show();
  };

  const updateRow = (user: UserInfo) => {
    const userData = {
      ...user,
      joined: user.participant?.joined,
    };
    setModalItem(userData);
    setOnlyView(false);
    cuvModal.show();
  };

  const deleteRow = (user: UserInfo) => {
    setModalTitle(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        user.first_name
      } ${user.last_name}</b> ${getStringByLanguage(
        'DOCUMENT',
      ).toLowerCase()}?`,
    );
    setModalDeleteItems([user]);
    deleteModal.show();
  };

  const onDeleteSelected = () => {
    if (!selectedItems.selectedCount) {
      return;
    }
    setModalTitle(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedItems.selectedCount
      } ${getStringByLanguage('DEVICES').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(selectedItems.selectedRows);
    deleteModal.show();
  };

  const onCreateNew = () => {
    setModalItem(null);
    setOnlyView(false);
    cuvModal.show();
  };

  const onInvite = async ({ id }: UserInfo) => {
    showLoadingBar();
    try {
      await inviteUser(id);
      toast.success(getStringByLanguage('EMAIL_SENT'));
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onCreate = async ({ phone, ...props }: Partial<UserInfo>) => {
    showLoadingBar();
    try {
      if (phone?.indexOf('+') === -1) {
        phone = '+' + phone;
      }
      await addUser({
        phone,
        ...props,
      });
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      cuvModal.hide();
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onUpdate = async ({
    id,
    phone,
    ...props
  }: Partial<UserInfo> & Partial<Pick<ParticipantInfo, 'joined'>>) => {
    showLoadingBar();
    try {
      if (phone?.indexOf('+') === -1) {
        phone = '+' + phone;
      }
      await updateUserInfo({
        id,
        phone,
        ...props,
      });
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      cuvModal.hide();
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async (params: { forever?: boolean }) => {
    showLoadingBar();

    const promises = modalDeleteItems.map(async (user) => {
      if (!user.id) {
        throw getStringByLanguage('REQUEST_ERROR');
      }
      return await deleteUser(user.id, params.forever);
    });
    try {
      await promises;
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setSelectedItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      deleteModal.hide();
      setTableUrlParam({ ...tableUrlParam });
      setListUrlParam({ ...listUrlParam });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<UserInfo>[];
  }) => {
    setSelectedItems(selected);
  };

  const onSortChange = (sortField: keyof UserInfo) => {
    const sort: SortOrder = listUrlParam.sort;
    if (listUrlParam.sortField === sortField) {
      setListUrlParam({
        ...listUrlParam,
        sort: sort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      });
    } else {
      setListUrlParam({
        ...listUrlParam,
        sortField,
        sort: SortOrder.ASC,
      });
    }
  };

  const ActionComponent: FC<{ data: UserInfo }> = ({ data }) => (
    <Popup
      className="action-popup"
      trigger={
        <div style={{ padding: '0px 10px' }}>
          <BsThreeDotsVertical />
        </div>
      }
      position={['bottom right']}
      arrow={false}
      contentStyle={{
        width: 'initial',
        backgroundColor: 'rgb(var(--content-background-color))',
        borderColor: 'rgb(var(--background-color))',
        padding: '5px 0px',
        boxShadow: '2px 2px 5px 0px rgba(var(--text-color), .2)',
      }}
    >
      <div className={styles['action-item-list']}>
        <span
          className={cn([styles['action-button-invite']])}
          onClick={() => onInvite(data)}
        >
          {getStringByLanguage('INVITE')}
        </span>
        <span
          className={cn([styles['action-button-update']])}
          onClick={() => updateRow(data)}
        >
          {getStringByLanguage('UPDATE')}
        </span>
        <span
          className={cn([styles['action-button-delete']])}
          onClick={() => deleteRow(data)}
        >
          {getStringByLanguage('DELETE')}
        </span>
      </div>
    </Popup>
  );

  const columns: TableColumn<UserInfo>[] = [
    {
      name: getStringByLanguage('NAME'),
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      cell: (data) => (
        <div className={cn([styles['table-item-participant-name']])}>
          <div
            style={{
              width: '35px',
              height: '35px',
              borderRadius: '50%',
            }}
          >
            <img
              alt="Avatar"
              src={
                (data?.avatar && getServerImageUrl(data.avatar)) ||
                (data?.gender && defaultAvatars[data.gender]) ||
                defaultAvatars['Male']
              }
            />
          </div>
          <div
            className={cn(['kut-tooltip-item'])}
            style={{ maxWidth: '63%' }}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {data.participant?.participant_name ||
                  `${data.first_name} ${data.last_name}`}
              </div>,
            )}
          >
            {data.participant?.participant_name ||
              `${data.first_name} ${data.last_name}`}
          </div>
        </div>
      ),
    },
    {
      name: getStringByLanguage('EMAIL'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return (
          <div
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>{data.email}</div>,
            )}
          >
            {data.email}
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('FIRST_NAME'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      selector: (data) => data.first_name,
    },
    {
      name: getStringByLanguage('LAST_NAME'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      selector: (data) => data.last_name,
    },
    {
      name: getStringByLanguage('ROLE'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      selector: (row) => printUserRole(row.role),
    },
    {
      name: getStringByLanguage('PRONOUNS'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      selector: (row) => row.gender,
    },
    {
      name: getStringByLanguage('PHONE'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return (
          <div
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>{data.phone}</div>,
            )}
          >
            {data.phone}
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('COMMUNITY'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      cell: (row) => {
        return (
          <div
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {row?.participant?.community?.community_name}
              </div>,
            )}
          >
            {row?.participant?.community?.community_name}
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('COMPANY_NAME'),
      sortable: true,
      minWidth: '50px',
      style: {
        cursor: 'pointer',
      },
      cell: (row) => {
        return (
          <div
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {row.company_name}
              </div>,
            )}
          >
            {row.company_name}
          </div>
        );
      },
    },
    {
      name: '',
      width: '2.813rem',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return <ActionComponent data={data} />;
      },
    },
  ];

  const cuvModal = useModal<Partial<UserInfo>>({
    onSubmit: modalItem ? onUpdate : onCreate,
    initialValues: modalItem || {
      email: '',
      first_name: '',
      last_name: '',
      role: UserRole.Participant,
      gender: Gender.Male,
      phone: '',
      company_name: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      first_name: Yup.string().when('role', {
        is: (role: UserRole) => role !== UserRole.Participant,
        then: Yup.string().required(
          getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
        ),
        otherwise: Yup.string().notRequired(),
      }),
      last_name: Yup.string().when('role', {
        is: (role: UserRole) => role !== UserRole.Participant,
        then: Yup.string().required(
          getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
        ),
        otherwise: Yup.string().notRequired(),
      }),
      phone: Yup.string()
        // .test((value) => {
        //   if (!value) {
        //     return true;
        //   }
        //   try {
        //     const parsed = parsePhoneNumber('+' + value);
        //     if (!parsed) {
        //       return false;
        //     }
        //     return parsed.isValid();
        //   } catch (error) {
        //     return false;
        //   }
        // })
        .notRequired(),
      role: Yup.string()
        .oneOf([
          UserRole.Participant,
          UserRole.Community_Manager,
          UserRole.Sub_Admin,
          UserRole.Super_Admin,
        ])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      gender: Yup.string()
        .oneOf([Gender.Male, Gender.Female])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      company_name: Yup.string().notRequired().nullable(),
    }),
  });
  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {
      forever: false,
    },
    validationSchema: Yup.object().shape({
      forever: Yup.boolean().required(),
    }),
  });

  return (
    <div className={styles['kut-component-body']}>
      <div className={styles['kut-component-content']}>
        <div className={styles['kut-component-header']}>
          <div
            className={cn([
              styles['kut-component-sort'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <span>{getStringByLanguage('SORT_BY')}</span>
            {sortFieldList.map((field, i) => (
              <span
                key={i}
                className={cn([
                  styles['kut-component-sort-option'],
                  listUrlParam.sortField === field ? styles['active'] : '',
                  styles[listUrlParam.sort],
                ])}
                onClick={() => onSortChange(field)}
              >
                {sortStringList[i]}
              </span>
            ))}
          </div>
          <div
            className={cn([
              styles['kut-component-select-all'],
              activeView === 1 ? styles['hide'] : '',
            ])}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(event) => {
                const status = event.target.checked;
                setSelectAll(status);
                setIsEventFromAll(true);
              }}
              ref={(input) => {
                if (input) {
                  const status = selectAll;
                  if (!status && selectedItems.selectedCount) {
                    input.indeterminate = true;
                    return;
                  }
                  input.indeterminate = false;
                  status ? setSelectAll(true) : setSelectAll(false);
                }
              }}
            />
            <span>{getStringByLanguage('SELECT_ALL')}</span>
          </div>
          {!isMobile && (
            <SwitchView
              className={styles['kut-component-switch-view']}
              items={switchItems}
              selected={activeView}
              onViewChange={onViewChange}
            />
          )}
        </div>
        {activeView === 1 && (
          <div
            className={cn([
              styles['kut-component-table'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              {userInfo?.role === UserRole.Super_Admin && (
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-large'],
                  ])}
                  outline={true}
                  onClick={onCreateNew}
                >
                  + {getStringByLanguage('CREATE_NEW')}
                </Button>
              )}
              <SearchInput
                className={styles['kut-component-search']}
                value={tableUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTableUrlParam({
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <Table
              pagination={true}
              selectableRows
              onRowClicked={onRowClicked}
              onSelectedRowsChange={onItemSelected}
              urlParam={tableUrlParam}
              requestFunc={(options) => getUsers({ ...options })}
              fieldDict={fieldDict}
              columns={columns}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        )}
        {activeView === 0 && (
          <div
            className={cn([
              styles['kut-component-list-view'],
              styles['kut-component-active'],
            ])}
          >
            <div className={styles['kut-component-actions']}>
              {userInfo?.role === UserRole.Super_Admin && (
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-large'],
                  ])}
                  outline={true}
                  onClick={onCreateNew}
                >
                  + {getStringByLanguage('CREATE_NEW')}
                </Button>
              )}
              <SearchInput
                className={styles['kut-component-search']}
                value={listUrlParam.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setListUrlParam({
                    ...listUrlParam,
                    search: event.target.value,
                  })
                }
              />
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                {getStringByLanguage('DELETE_SELECTED')}
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                ])}
                onClick={onCreateNew}
              >
                <BsPlus />
              </Button>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-small'],
                  styles['kut-component-button-delete'],
                  selectedItems.selectedCount ? styles['kut-active'] : '',
                ])}
                type={BUTTON_TYPE.DANGER}
                onClick={onDeleteSelected}
              >
                <BsTrash2 />
              </Button>
            </div>
            <ListView
              urlParam={listUrlParam}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              isEventFromAll={isEventFromAll}
              setIsEventFromAll={setIsEventFromAll}
              onItemSelected={onItemSelected}
              requestFunc={(options) =>
                getUsers({ ...options, community_id, participant_id })
              }
              ChildComponent={({
                className,
                onChange,
                checked,
                data,
              }: {
                className: string;
                onChange: (status: boolean) => void;
                checked: boolean;
                data: UserInfo;
              }) => {
                return (
                  <div
                    className={cn([
                      className,
                      styles['kut-component-list-item'],
                    ])}
                  >
                    <div className={cn([styles['list-item-heading']])}>
                      <div
                        className={cn([styles['list-item-participant-name']])}
                        onClick={() => onRowClicked(data)}
                      >
                        <img
                          alt="Avatar"
                          src={
                            (data?.avatar && getServerImageUrl(data.avatar)) ||
                            (data?.gender && defaultAvatars[data.gender]) ||
                            defaultAvatars['Male']
                          }
                          style={{ width: '2em', height: '2em' }}
                        />
                        &nbsp;
                        {data.participant?.participant_name ||
                          `${data.first_name} ${data.last_name}`}
                      </div>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(event) => onChange(event.target.checked)}
                      />
                    </div>
                    <div className={cn([styles['list-item-user-content']])}>
                      <div className={cn([styles['list-item-user-email']])}>
                        <span>{getStringByLanguage('EMAIL')}</span>
                        <p>{data.email}</p>
                      </div>
                      <div
                        className={cn([styles['list-item-user-first-name']])}
                      >
                        <span>{getStringByLanguage('FIRST_NAME')}</span>
                        <p>{data.first_name}</p>
                      </div>
                      <div className={cn([styles['list-item-user-last-name']])}>
                        <span>{getStringByLanguage('LAST_NAME')}</span>
                        <p>{data.last_name}</p>
                      </div>
                      <div className={cn([styles['list-item-user-role']])}>
                        <span>{getStringByLanguage('ROLE')}</span>
                        <p>{printUserRole(data.role)}</p>
                      </div>
                      <div className={cn([styles['list-item-user-gender']])}>
                        <span>{getStringByLanguage('PRONOUNS')}</span>
                        <p>{data.gender}</p>
                      </div>
                      <div className={cn([styles['list-item-user-phone']])}>
                        <span>{getStringByLanguage('PHONE')}</span>
                        <p>{data.phone}</p>
                      </div>
                      <div className={cn([styles['list-item-user-community']])}>
                        <span>{getStringByLanguage('COMMUNITY')}</span>
                        <p>{data?.participant?.community?.community_name}</p>
                      </div>
                    </div>
                    <div className={cn([styles['list-item-actions']])}>
                      <div></div>
                      <div>
                        <span
                          className={cn([styles['list-item-button-update']])}
                          onClick={() => updateRow(data)}
                        >
                          {getStringByLanguage('UPDATE')}
                        </span>
                        <span
                          className={cn([styles['list-item-button-delete']])}
                          onClick={() => deleteRow(data)}
                        >
                          {getStringByLanguage('DELETE')}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
        <Modal {...cuvModal}>
          {(formik) => {
            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-update-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {onlyView
                    ? getStringByLanguage('USER')
                    : modalItem
                    ? getStringByLanguage(['UPDATE', 'USER'])
                    : getStringByLanguage(['CREATE', 'USER'])}
                </p>
                <hr />
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-email'],
                  ])}
                  heading={getStringByLanguage('EMAIL')}
                  name={'email'}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  required
                  error={formik.touched.email && formik.errors.email}
                />
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-first-name'],
                  ])}
                  heading={getStringByLanguage('FIRST_NAME')}
                  name={'first_name'}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  required
                  error={formik.touched.first_name && formik.errors.first_name}
                />
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-last-name'],
                  ])}
                  heading={getStringByLanguage('LAST_NAME')}
                  name={'last_name'}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  required
                  error={formik.touched.last_name && formik.errors.last_name}
                />
                <PhoneInput
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-phone'],
                  ])}
                  heading={getStringByLanguage('PHONE')}
                  value={formik.values.phone}
                  onChange={(value) => formik.setFieldValue('phone', value)}
                  onMount={(value) => formik.setFieldValue('phone', value)}
                  error={formik.touched.phone && formik.errors.phone}
                />
                {formik.values.participant && (
                  <InputForm
                    className={cn([styles['kut-input-form']])}
                    type={'date'}
                    heading={getStringByLanguage('JOINED')}
                    name={'joined'}
                    onChange={formik.handleChange}
                    value={formik.values.joined?.slice(0, 10)}
                    error={formik.errors.joined}
                    style={{
                      backgroundColor: `rgb(var(--background-color))`,
                    }}
                  />
                )}
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-role'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('ROLE')}
                    </span>
                    <Select
                      className={cn([styles['input-form-content']])}
                      options={userRoleTypes}
                      filterOption={filterOption}
                      name={`role`}
                      isSearchable
                      required
                      onChange={(v) => {
                        formik.setFieldValue('role', v?.value);
                      }}
                      value={userRoleTypes.find(
                        (value) => value.value === formik.values.role,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-gender'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('GENDER')}
                    </span>
                    <Select
                      className={cn([styles['input-form-content']])}
                      options={genderTypes}
                      filterOption={filterOption}
                      name={`gender`}
                      isSearchable
                      required
                      onChange={(v) => {
                        formik.setFieldValue('gender', v?.value);
                      }}
                      value={genderTypes.find(
                        (value) => value.value === formik.values.gender,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <InputForm
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-company-name'],
                  ])}
                  heading={getStringByLanguage('COMPANY_NAME')}
                  name={'company_name'}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.company_name && formik.errors.company_name
                  }
                />
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {modalItem
                      ? getStringByLanguage(['SAVE_UPDATES'])
                      : getStringByLanguage(['CREATE'])}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => cuvModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalTitle }} />
              <hr />
              <div className={cn([styles['kut-form-delete-forever']])}>
                <span
                  style={{
                    color: `rgb(var(--text-color))`,
                  }}
                >
                  {getStringByLanguage('DELETE_FOREVER')}?
                </span>
                <IOSSwitch
                  name={'forever'}
                  checked={formik.values.forever}
                  onChange={formik.handleChange}
                />
              </div>
              <p
                className={cn([styles['kut-form-delete-forever-description']])}
              >
                {getStringByLanguage('DELETE_FOREVER_DESCRIPTION')}
              </p>
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Documents;

import { FC, HTMLAttributes, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as StarsSVG } from '../../assets/images/stars.svg';
import { RxCrossCircled } from 'react-icons/rx';
import Button, { BUTTON_TYPE } from '../Button/Button';
import { getStringByLanguage } from '../../utils/utils';
import {
  NOTIFICATION_STATUS,
  updateNotificationStatus,
  NotificationInfo,
} from '../../apis/notifications';

import cn from 'classnames';
import { toast } from 'react-toastify';
interface FuncGroup extends HTMLAttributes<HTMLDivElement> {
  data: NotificationInfo;
}
const NotificationItem: FC<FuncGroup> = ({ data }) => {
  const [isShow, setIsShow] = useState(
    data.notification_status === 0 ? true : false,
  );

  const handleButtons = async ({
    status,
    value,
  }: {
    status: keyof typeof NOTIFICATION_STATUS;
    value: boolean;
  }) => {
    const response = await updateNotificationStatus({
      data,
      status,
      value,
    });
    if (response) {
      (response.data?.data?.notification_status ?? 0) > 0
        ? setIsShow(false)
        : setIsShow(true);
    }
    if (value) {
      toast.success(
        getStringByLanguage('SUCCESSFULLY_FOLLOWED') +
          data.notification_fields?.shineFrom +
          ' ~ ' +
          data.notification_fields?.shineTo,
      );
    } else {
      toast.success(
        getStringByLanguage('SUCCESSFULLY_NOT_FOLLOWED') +
          data.notification_fields?.shineFrom +
          ' ~ ' +
          data.notification_fields?.shineTo,
      );
    }
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  // handleButtons({ status: 'NOT_AGAIN', value: true });

  const { shineFrom, shineTo } = data.notification_fields || {};

  return isShow ? (
    <div className={styles['ni-body']}>
      <div className={styles['ni-content']}>
        <div className={styles['ni-content-recommend']}>
          <StarsSVG className={cn([styles['ni-content-icon']])} />
          <div className={cn([styles['ni-content-body']])}>
            <p className={cn([styles['ni-content-text']])}>AI recommendation</p>
            <div className={cn([styles['ni-content-title']])}>
              <span className={styles['ni-content-prefix']}>
                The sun will shine
              </span>{' '}
              from {shineFrom.toString()} - {shineTo.toString()}
            </div>
          </div>
        </div>
        <RxCrossCircled className={cn([styles['ni-content-close']])} />
      </div>
      <div className={styles['ni-content-footer']}>
        <Button
          className={cn([styles['ni-footer-ok']])}
          type={BUTTON_TYPE.PRIMARY}
          onClick={() =>
            handleButtons({
              status: 'FOLLOWED',
              value: true,
            })
          }
        >
          {getStringByLanguage('FOLLOW_RECOMMENDATION')}
        </Button>
        <Button
          className={cn([styles['ni-footer-ok']])}
          type={BUTTON_TYPE.SECONDARY}
          onClick={() =>
            handleButtons({
              status: 'NOT_AGAIN',
              value: false,
            })
          }
          outline
        >
          {getStringByLanguage('DONT_FOLLOW_RECOMMENDATION')}
        </Button>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default NotificationItem;
